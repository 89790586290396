export const menus = [
    // {
    //     "name": "Dashboard",
    //     "icon": "dashboard",
    //     "open": true,
    //     "chip": {
    //         "value": "1",
    //         "color": "accent"
    //     },
    //     "link": "/admin/dashboard"
    // },
    // {
    //     "name": "Chức năng",
    //     "icon": "question_answer",
    //     "link": false,
    //     "open": false,
    //     "chip": {
    //         "value": "3",
    //         "color": "accent"
    //     },
    //     "sub": [
    //         {
    //             "name": "Thông báo",
    //             "link": "/admin/search/notification",
    //             "icon": "question_answer",
    //             "chip": false,
    //             "open": true
    //         },
    //         {
    //             "name": "Yêu cầu",
    //             "link": "/admin/search/request",
    //             "icon": "supervisor_account",
    //             "chip": false,
    //             "open": true
    //         },
    //         {
    //             "name": "Thanh toán",
    //             "link": "/admin/search/bill",
    //             "icon": "find_in_page",
    //             "chip": false,
    //             "open": true
    //         }
    //     ]
    // },
    // {
    //     "name": "Danh mục",
    //     "icon": "reorder",
    //     "link": false,
    //     "open": false,
    //     "chip": {
    //         "value": "3",
    //         "color": "accent"
    //     },
    //     "sub": [
    //         {
    //             "name": "Mẫu thông báo",
    //             "link": "/admin/search/notification-template",
    //             "icon": "question_answer",
    //             "chip": false,
    //             "open": true
    //         },
    //         {
    //             "name": "Loại yêu cầu",
    //             "link": "/admin/request-type",
    //             "icon": "question_answer",
    //             "chip": false,
    //             "open": true
    //         },
    //         {
    //             "name": "Phòng ban",
    //             "link": "/admin/department",
    //             "icon": "question_answer",
    //             "chip": false,
    //             "open": true
    //         }
    //     ]
    // },
    // {
    //     "name": "Hệ thống",
    //     "icon": "settings",
    //     "link": false,
    //     "open": false,
    //     "chip": {
    //         "value": "2",
    //         "color": "accent"
    //     },
    //     "sub": [
    //         {
    //             "name": "Người dùng",
    //             "link": "/admin/search/user",
    //             "icon": "supervisor_account",
    //             "chip": false,
    //             "open": true
    //         },
    //         {
    //             "name": "Danh sách chưa cài đặt",
    //             "link": "/admin/search/settings",
    //             "icon": "build",
    //             "chip": false,
    //             "open": true
    //         },
    //         {
    //             "name": "Tin nhắn chờ gửi",
    //             "link": "/admin/search/sms-pending",
    //             "icon": "question_answer",
    //             "chip": false,
    //             "open": true
    //         },
    //         {
    //             "name": "Tin nhắn đã gửi",
    //             "link": "/admin/search/sms-sent",
    //             "icon": "question_answer",
    //             "chip": false,
    //             "open": true
    //         },
    //         {
    //             "name": "Thông báo chờ gửi",
    //             "link": "/admin/search/notification-pending",
    //             "icon": "question_answer",
    //             "chip": false,
    //             "open": true
    //         },
    //         {
    //             "name": "Thông báo đã gửi",
    //             "link": "/admin/search/notification-sent",
    //             "icon": "question_answer",
    //             "chip": false,
    //             "open": true
    //         }
    //     ]
    // },
    // {
    //     "name": "Lịch bàn giao",
    //     "icon": "settings",
    //     "link": false,
    //     "open": false,
    //     "chip": {
    //         "value": "2",
    //         "color": "accent"
    //     },
    //     "sub": [
    //         {
    //             "name": "Danh sách lịch",
    //             "link": "/admin/calendar/list",
    //             "icon": "supervisor_account",
    //             "chip": false,
    //             "open": true
    //         },
    //         {
    //             "name": "Văn phòng",
    //             "link": "/admin/calendar/office",
    //             "icon": "build",
    //             "chip": false,
    //             "open": true
    //         }
    //     ]
    // }n
];