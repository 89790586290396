import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Inject } from '@angular/core';
import { ModuleField } from '../../../../core/models/ModuleField';
import { ModuleService } from '../../../../core/services/module.service';
import {
    FormControl, FormGroup, Validators, FormBuilder, ReactiveFormsModule
} from '@angular/forms';
import { ModuleInfo } from '../../../../common/models/moduleInfo';
@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswrodDialogComponent
    implements OnInit, OnDestroy {
    fields: ModuleField[] = [];
    private form = new FormGroup({});
    constructor(
        private moduleService: ModuleService,
        private dialogRef: MatDialogRef<ResetPasswrodDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private formBuilder: FormBuilder
    ) {

    }

    ngOnInit() {
        this.form = this.createForm();
    }

    createForm(): FormGroup {
        const formControls: FormGroup | any = {};
        formControls["Password"] = this.formBuilder.control(null);
        formControls["PasswordRepeat"] = this.formBuilder.control(null);
        return this.formBuilder.group(formControls);
    }

    hasError(controlName: string, errorName: string) {
        return this.form.controls[controlName].hasError(errorName);
    }

    onSubmit() {
        let mod = new ModuleInfo("USERADMINRESETPASSWORD", "MMN", {
            "Password": this.form.controls["Password"].value,
            "PasswordRepeat": this.form.controls["PasswordRepeat"].value
        });
        let res = this.moduleService.executeModule(mod).subscribe((res: any) => {
            if (res.status == 1) {
                window.alert('Đổi mật khẩu thành công');
                this.data.parent.onLogoutResetPassword(this.data.parent);
                this.dialogRef.close();
            } else {
                alert(res.message);
            }
        });
    }

    ngOnDestroy() {

    }
}