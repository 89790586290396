import { Injectable } from '@angular/core';
import { ModuleInfo } from '../../common/models/moduleInfo';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { config } from '../../common/config';
import { HttpService } from './http.service';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    apiUri: string = `${config.apiUrl}/api/auth`;
    constructor(
        public http: HttpService
    ) { }
    public authData;
    login(username: string, password: string) {
        let url = `${this.apiUri}/admin-login`;
        return this.http.post(url, { userName: username, password: password, accessKey: "" });
    }

    getAuthData() {
        let dt = localStorage.getItem("auth_data");
        if (dt) {
            return JSON.parse(dt)
        }
        return null;
    }

    setAuthMenu(menu) {
        if (menu)
            localStorage.setItem('auth_menu', JSON.stringify(menu));
    }

    getAuthMenu() {
        let dt = localStorage.getItem("auth_menu");
        if (dt) {
            return JSON.parse(dt)
        }
        return null;
    }

    setAuthData(authData) {
        if (authData)
            localStorage.setItem('auth_data', JSON.stringify(authData));
    }

    getAppKey() {
        let dt = localStorage.getItem("appkey");
        return dt;
    }
}