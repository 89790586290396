import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { config } from '../../common/config';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    constructor(
        public http: HttpClient
    ) { }

    post(url: string, data: any) {
        let token = localStorage.getItem("auth_token");
        return this.http.post(url, data, {
            headers: {
                "Authorization": "Bearer " + token
            }
        })
    }

    post_urlencoded(url: string, data: any) {
        let token = localStorage.getItem("auth_token");
        var urlencoded = new URLSearchParams();
        urlencoded.append("username", data.userName);
        urlencoded.append("password", data.passWord);
        urlencoded.append("client_id", "api");
        urlencoded.append("client_secret", "QuyetHS");
        urlencoded.append("scope", "api1");
        urlencoded.append("grant_type", "password");
        urlencoded.append("accessKey", "APEC");
        const body = new URLSearchParams()
        body.set('username', data.userName);
        body.set('password', data.password);
        body.set("client_id", "api");
        body.append("client_secret", "QuyetHS");
        body.append("scope", "api1");
        body.append("grant_type", "password");
        body.append("accessKey", "APEC");

        return this.http.post(url, body.toString(),
            {
                headers: new HttpHeaders()
                    .set('Content-Type', 'application/x-www-form-urlencoded')
                    .set('Authorization', 'Bearer ' + token)
            });
    }

    get(url: string) {
        let token = localStorage.getItem("auth_token");
        return this.http.get(url, {
            headers: {
                "Authorization": "Bearer " + token
            }
        })
    }

    fetchUrl(url) {
        return this.http.get(url);
    }

}