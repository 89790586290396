import { Component } from '@angular/core';
import { Subject } from 'rxjs';
import { LoaderService } from './loader.service';
import { delay } from 'rxjs/operators';
@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {
  color = 'primary';
  mode = 'indeterminate';
  value = 50;
  isLoading: Subject<boolean>;
  constructor(private loaderService: LoaderService) { }
  ngAfterViewInit() {
    setTimeout(()=>{
      this.isLoading = this.loaderService.isLoading;
    })
  }
}